import React from 'react'
import { graphql, PageProps } from 'gatsby'

import { MainLayout, withLanguageProvider } from '@tmp'
import { GetVenuePageQuery } from '@gql-types'
import { Button, HeroHeader, Link, RichText, Text } from '@cmp'
import { GatsbyImage } from 'gatsby-plugin-image'
import cn from 'classnames'
import Masonry from 'react-masonry-css'
import { assertDefined } from '@src/utils/helpers'
import Locale from '@src/utils/locale'
import { useLanguage } from '../context'

const VenuePageTemplate = ({ data: { content, localizedPages } }: PageProps<GetVenuePageQuery>) => {
  content = assertDefined(content)
  const { t } = useLanguage()

  const techTableLength = content?.technicalInfo?.tableData?.length || 0
  const filesTableLength = content?.files?.length || 0

  return (
    <MainLayout
      seo={content.seo}
      defaultSeoOverwrites={{ title: content.title, ogTitle: content.title }}
      localizedUrls={localizedPages.nodes.map(page => ({
        locale: page.node_locale as Locale,
        route: { type: 'venue', slug: page.slug ?? '', parentSlug: page.parentPage?.slug },
      }))}
    >
      {content.title && (
        <HeroHeader
          title={content.title}
          intro={content.intro?.intro || ''}
          image={content.headerImage?.gatsbyImageData}
          className='gutter'
        />
      )}
      <div className='gutter'>
        <div className='mx-auto mt-16 lg:w-8/12'>
          {content.informationText?.introText && (
            <Text variant='intro' className='mb-8'>
              {content.informationText?.introText.introText}
            </Text>
          )}
          {content.informationText?.richText && (
            <RichText
              document={content.informationText.richText ?? undefined}
              className='mb-20'
              textStyles={{ weight: 'light' }}
            />
          )}
          {content?.technicalInfo?.tableData && techTableLength > 0 && (
            <div className='mb-20'>
              <Text variant='h3' weight='heavy' className='mb-8'>
                {content.technicalInfoTitle}
              </Text>
              {content?.technicalInfo?.tableData?.map((technical, index) => (
                <div
                  className={cn('px-6 py-4 border-dark-300 border-t border-l border-r', {
                    'border-b': index === techTableLength - 1,
                  })}
                  key={index}
                >
                  {technical}
                </div>
              ))}
            </div>
          )}
          {content?.files && filesTableLength > 0 && (
            <div className='mb-20'>
              <Text variant='h3' weight='heavy' className='mb-8'>
                {content.filesTitle}
              </Text>
              {content?.files?.map((file, index) => (
                <div
                  className={cn('flex border-dark-300 border-t border-l border-r', {
                    'border-b': index === filesTableLength - 1,
                  })}
                  key={index}
                >
                  <span className='px-6 py-4'>{file?.title}</span>
                  <span className='flex items-center px-6 ml-auto border-l md:px-12 border-dark-300'>
                    {file?.file?.url && (
                      <Button size='sm' href={file?.file?.url}>
                        {`${t('download')} pdf`}
                      </Button>
                    )}
                  </span>
                </div>
              ))}
            </div>
          )}
          {content?.googleMapsEmbeds && content?.googleMapsEmbeds?.length > 0 && (
            <div className='mb-20'>
              <Text variant='h3' weight='heavy' className='mb-8'>
                {content.googleMapsTitle}
              </Text>
              {content.googleMapsEmbeds.map((embed, idx) => (
                <div className='h-0 relative pb-[56.25%]' key={idx}>
                  <iframe
                    src={embed!}
                    className='absolute top-0 left-0 w-full h-full'
                    frameBorder='0'
                    style={{ border: 0 }}
                    allowFullScreen={false}
                    aria-hidden='false'
                    tabIndex={0}
                  />
                </div>
              ))}
            </div>
          )}
          {content.bookingInfo && (
            <div className='mb-20'>
              <Text variant='h3' weight='heavy' className='mb-8'>
                {content.bookingInfo.title}
              </Text>
              {content.bookingInfo?.departments && content.bookingInfo?.departments?.length > 0 && (
                <div className='-mx-2.5 flex'>
                  {content.bookingInfo?.departments?.map((department, idx) => (
                    <div className='px-2.5 w-6/12 md:w-3/8' key={idx}>
                      {department?.photo?.gatsbyImageData && (
                        <GatsbyImage className='w-full mb-4' alt='' image={department?.photo?.gatsbyImageData} />
                      )}
                      <div className='mb-4'>
                        <Text>{department?.role}</Text>
                      </div>
                      <div>
                        {department?.email && (
                          <Text>
                            <Link to={`mailto:${department?.email}`}>{department?.email}</Link>
                          </Text>
                        )}
                        {department?.phoneNumber && (
                          <Text>
                            <Link to={`tel:+354-${department?.phoneNumber}`}>{department?.phoneNumber}</Link>
                          </Text>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}
          {content.gallery && (
            <div className='mb-20'>
              <Text variant='h3' className='mb-8'>
                {t('gallery')}
              </Text>
              <Masonry
                breakpointCols={{ default: 3, 800: 2 }}
                className='flex w-auto -ml-5'
                columnClassName='bg-clip-padding pl-5'
              >
                {content.gallery.map((img, idx) => (
                  <GatsbyImage key={idx} alt='' image={img?.gatsbyImageData} className='mb-5' />
                ))}
              </Masonry>
            </div>
          )}
        </div>
      </div>
    </MainLayout>
  )
}

export default withLanguageProvider(VenuePageTemplate)

export const venuePageQuery = graphql`
  query GetVenuePage($contentfulId: String, $locale: String) {
    content: contentfulVenuePage(contentful_id: { eq: $contentfulId }, node_locale: { eq: $locale }) {
      ...venuePageFragment
    }

    localizedPages: allContentfulVenuePage(filter: { contentful_id: { eq: $contentfulId } }) {
      nodes {
        node_locale
        slug
        parentPage {
          slug
        }
      }
    }
  }
`
